import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from "gatsby"

const AboutUs = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Error 404"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Error 404"
      />
      <section id="not-found">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
            </div>
            <div className="col-md-6 info-text">
              <h2 className="mb-5 h3-title">Page not found</h2>
              <p>The page you are trying to access no longer exists or has been moved.</p>
              <p>Return home or try to reach us on the <Link to="/contact/">contact page</Link>.</p>
              <Link class="view-more-button" to="/">
                Home
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
}

export default AboutUs;
